export function ArrowLeft(props) {
    return (
        <svg
            viewBox="0 0 448 512"
            role="img"
            aria-hidden="true"
            focusable="false"
            {...props}>
            <path d="M257.5 445.1l-22.2 22.2c-9.4 9.4-24.6 9.4-33.9 0L7 273c-9.4-9.4-9.4-24.6 0-33.9L201.4 44.7c9.4-9.4 24.6-9.4 33.9 0l22.2 22.2c9.5 9.5 9.3 25-.4 34.3L136.6 216H424c13.3 0 24 10.7 24 24v32c0 13.3-10.7 24-24 24H136.6l120.5 114.8c9.8 9.3 10 24.8.4 34.3z" />
        </svg>
    );
}

export function ArrowRight(props) {
    return (
        <svg
            viewBox="0 0 448 512"
            role="img"
            aria-hidden="true"
            focusable="false"
            {...props}>
            <path d="M190.5 66.9l22.2-22.2c9.4-9.4 24.6-9.4 33.9 0L441 239c9.4 9.4 9.4 24.6 0 33.9L246.6 467.3c-9.4 9.4-24.6 9.4-33.9 0l-22.2-22.2c-9.5-9.5-9.3-25 .4-34.3L311.4 296H24c-13.3 0-24-10.7-24-24v-32c0-13.3 10.7-24 24-24h287.4L190.9 101.2c-9.8-9.3-10-24.8-.4-34.3z" />
        </svg>
    );
}

export const ArrowRightPagination = (props) => {
    return (
        <svg
            width={props.width || 20}
            height={props.height || 15}
            viewBox="0 0 20 15"
            {...props}>
            <title>right-arrow copy</title>
            <path
                d="M13.253.63a.695.695 0 0 0-.992 0 .7.7 0 0 0 0 .981l5.03 5.03H.694A.691.691 0 0 0 0 7.336c0 .386.307.704.694.704h16.597l-5.03 5.02a.712.712 0 0 0 0 .992.695.695 0 0 0 .992 0l6.22-6.22a.683.683 0 0 0 0-.982L13.253.63z"
                fill={props.fill ? props.fill : 'var(--primary)'}
                fillRule="nonzero"
            />
        </svg>
    );
};

export function ArrowUp(props) {
    return (
        <svg
            viewBox="0 0 448 512"
            role="img"
            aria-hidden="true"
            focusable="false"
            {...props}>
            <path d="M34.9 289.5l-22.2-22.2c-9.4-9.4-9.4-24.6 0-33.9L207 39c9.4-9.4 24.6-9.4 33.9 0l194.3 194.3c9.4 9.4 9.4 24.6 0 33.9L413 289.4c-9.5 9.5-25 9.3-34.3-.4L264 168.6V456c0 13.3-10.7 24-24 24h-32c-13.3 0-24-10.7-24-24V168.6L69.2 289.1c-9.3 9.8-24.8 10-34.3.4z" />
        </svg>
    );
}

export function ArrowDown(props) {
    return (
        <svg
            viewBox="0 0 448 512"
            role="img"
            aria-hidden="true"
            focusable="false"
            {...props}>
            <path d="M413.1 222.5l22.2 22.2c9.4 9.4 9.4 24.6 0 33.9L241 473c-9.4 9.4-24.6 9.4-33.9 0L12.7 278.6c-9.4-9.4-9.4-24.6 0-33.9l22.2-22.2c9.5-9.5 25-9.3 34.3.4L184 343.4V56c0-13.3 10.7-24 24-24h32c13.3 0 24 10.7 24 24v287.4l114.8-120.5c9.3-9.8 24.8-10 34.3-.4z" />
        </svg>
    );
}
