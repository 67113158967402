import React from 'react';
import Link from 'next/link';
import Head from 'next/head';

import Layout from '../components/Layout';
import MaxWidth from '../components/MaxWidth';
import Image from '../components/Image';
import HiddenOnMobile from '../components/HiddenOnMobile';
import Button from '../components/Button';
import HelpLineInfo from '../components/HelpLineInfo';
import CallTrackingNumberWrapper from '../components/CallTrackingNumberWrapper';

import {ArrowRight} from '../components/Svgs/Arrows';
import {BREAKPOINT} from '../lib/styles';

import {WPFetch} from 'aac-components/utils/fetch';
import {useEffect} from 'react';
import {setContentSegment} from '../lib/utils';

export async function getServerSideProps(context) {
    let contentSegment = 'conversion-pages';
    const clearCache = Boolean(context?.req?.query?.clearCache);
    const {data} = await WPFetch(
        `/wordpress/wp/v2/pages/?slug=homepage,home&_fields=content_segment&limit=1`,
        clearCache,
    );
    const segment_data = data || null;

    if (segment_data && !segment_data.error)
        contentSegment = segment_data?.[0]?.['content_segment'] ?? 'conversion-pages';

    return {props: {contentSegment}};
}

const Home = (props) => {
    /* To load default homepage content section */
    const {contentSegment = ''} = props;
    useEffect(() => {
        if (contentSegment) setContentSegment(contentSegment);
    }, [contentSegment]);

    return (
        <>
            <Head>
                <title>America’s Leading Resource for Withdrawal & Detox</title>
                <meta
                    name="description"
                    key="description"
                    content="Withdrawal can be the most difficult step in achieving sobriety. Withdrawal.net offers information about withdrawal, detox, and how to access treatment and is operated by a leading provider of addiction treatment in the United States; American Addiction Centers."
                />
                <meta
                    name="robots"
                    content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1"></meta>
                <link href="https://withdrawal.net/" rel="canonical"></link>
            </Head>
            <Layout>
                <section className="section__hero">
                    <div className="section__hero--inner">
                        <div className="section__hero--inner-left">
                            <div className="section__hero--copy">
                                <h1>
                                    We can help you get to a{' '}
                                    <span style={{color: '#055276'}}> sober future</span>
                                </h1>
                                <p className="section__hero--subtext text-sm">
                                    Withdrawal.net is an addiction treatment resource
                                    provided by American Addiction Centers (AAC),
                                    dedicated to helping you find withdrawal treatment
                                    options that meets your needs.
                                    <br />
                                    <br />
                                    If you or a loved one needs help our helpline is open
                                    24/7.
                                </p>
                            </div>
                            <div className="section__hero--button-container">
                                <CallTrackingNumberWrapper
                                    prefix="Call Today"
                                    styles="color: var(--white); font-size: 12px;"
                                    isButton
                                />
                                <div className="help-line" style={{marginTop: '-12px'}}>
                                    <HelpLineInfo dark={true} />
                                </div>
                            </div>
                        </div>
                        <HiddenOnMobile>
                            <Image
                                desktopImage="/static/home-page-hero.jpg"
                                style={{
                                    width: '570px',
                                    height: '464px',
                                    border: '6px solid rgb(255, 255, 255)',
                                }}
                            />
                        </HiddenOnMobile>
                    </div>
                </section>
                <MaxWidth>
                    <section className="section__two">
                        <Image
                            src="/static/withdrawal-symptoms-bg.jpg"
                            style={{borderRadius: '8px'}}>
                            <div className="section__two--card">
                                <span className="text-md">Withdrawal Symptoms</span>
                                <Link href="/symptoms/">
                                    <span>View All Drugs</span>{' '}
                                    <ArrowRight
                                        fill="#ef395a"
                                        width="20px"
                                        height="20px"
                                    />
                                </Link>
                            </div>
                        </Image>
                        <Image
                            src="/static/treatment-guides-bg.jpg"
                            style={{borderRadius: '8px'}}>
                            <div className="section__two--card">
                                <span className="text-md">Treatment Guides</span>
                                <Link href="/guides/">
                                    <span>View All Guides</span>{' '}
                                    <ArrowRight
                                        fill="#ef395a"
                                        width="20px"
                                        height="20px"
                                    />
                                </Link>
                            </div>
                        </Image>
                        <Image
                            src="/static/treatment-centers-bg.jpg"
                            style={{borderRadius: '8px'}}>
                            <div className="section__two--card">
                                <span className="text-md">Treatment Centers</span>
                                <Link href="/aac-facilities/">
                                    <span>View Our Locations</span>{' '}
                                    <ArrowRight
                                        fill="#ef395a"
                                        width="20px"
                                        height="20px"
                                    />
                                </Link>
                            </div>
                        </Image>
                    </section>
                    <section className="section__three">
                        <div className="section__three--copy">
                            <h2>We are in-network with many top insurance providers</h2>
                            <p className="text-sm">
                                To find out if your health insurance covers treatment use
                                our free online tool to verify your benefits.
                            </p>
                        </div>
                        <div className="section__three--insurance-logos">
                            <Image
                                src="/static/anthem-logo.png"
                                width="110"
                                height="50"
                                defer={true}
                            />
                            <Image src="/static/bcbs-logo.png" width="110" height="50" />
                            <Image
                                src="/static/humana-logo.png"
                                width="110"
                                height="50"
                                defer={true}
                            />
                            <Image
                                src="/static/amerigroup-logo.png"
                                width="110"
                                height="50"
                                defer={true}
                            />
                            <Image
                                src="/static/kaiser-logo.png"
                                width="110"
                                height="50"
                                defer={true}
                            />
                            <Image
                                src="/static/uhg-logo.png"
                                width="110"
                                height="50"
                                defer={true}
                            />
                        </div>
                        <div className="section__three--insurance-buttons">
                            <Link href="/verify-insurance/">
                                <Button>Check My Benefits Online</Button>
                            </Link>
                            <Link href="/insurance/">
                                <Button className="outlined">
                                    View All Insurance Providers
                                </Button>
                            </Link>
                        </div>
                    </section>
                </MaxWidth>
                <section className="section__four">
                    <Image src="/static/treatment-nationwide-bg-img.jpg" defer={true}>
                        <div className="section__four--inner">
                            <div className="section__four--aac-logo">
                                <Image
                                    src="/static/acc-vertical-lockup-white-alt.svg"
                                    width="192px"
                                    defer={true}
                                />
                            </div>
                            <div className="section__four--treatment-icons">
                                <div className="section__four--treatment-icon">
                                    <Image
                                        src="/static/icon-medical-detox.svg"
                                        alt="Medical Detox"
                                        width="48px"
                                        height="48"
                                        defer={true}
                                    />
                                    <span className="text-xs">Medical Detox</span>
                                </div>
                                <div className="section__four--treatment-icon">
                                    <Image
                                        src="/static/icon-inpatient-treatment.svg"
                                        alt="Inpatient Treatment"
                                        width="48px"
                                        height="48"
                                        defer={true}
                                    />
                                    <span className="text-xs">Inpatient Treatment</span>
                                </div>
                                <div className="section__four--treatment-icon">
                                    <Image
                                        src="/static/icon-outpatient-treatment.svg"
                                        alt="Outpatient Treatment"
                                        width="48px"
                                        height="48"
                                        defer={true}
                                    />
                                    <span className="text-xs">Outpatient Treatment</span>
                                </div>
                                <div className="section__four--treatment-icon">
                                    <Image
                                        src="/static/icon-telehealth.svg"
                                        alt="Telehealth Services"
                                        width="48px"
                                        height="48"
                                        defer={true}
                                    />
                                    <span className="text-xs">Telehealth Services</span>
                                </div>
                            </div>
                            <div className="section__four--copy">
                                <h2>Treatment Nationwide</h2>
                                <p>
                                    American Addiction Centers is a leading provider of
                                    both residential and outpatient addiction treatment
                                    services.
                                    <br />
                                    <br />
                                    All of our treatment centers provides its own
                                    combination of proven therapies and services to meet
                                    your needs.
                                </p>
                            </div>
                            <div className="section__four--button">
                                <Link href="/admissions/">
                                    <Button style={{width: '100%', padding: '16px'}}>
                                        Start the Admissions Process
                                    </Button>
                                </Link>
                            </div>
                        </div>
                    </Image>
                </section>
                <section className="section__five">
                    <MaxWidth>
                        <div className="section__five--inner">
                            <Image
                                src="/static/90-day-promise-aac-badge.png"
                                width="200px"
                                height="200px"
                                defer={true}
                            />
                            <div className="section__five--copy">
                                <h2>Our promise to you</h2>
                                <p className="text-sm">
                                    When you invest and successfully complete 90
                                    consecutive days at an American Addiction Centers
                                    facility, we believe that you have created the most
                                    solid foundation for your long-term goals of sobriety.
                                    In the event that you experience a relapse, you are
                                    welcome back for a complimentary 30 days of our
                                    treatment.*
                                </p>
                                <span className="text-xs">
                                    *Terms and conditions may apply, and results may vary
                                </span>
                            </div>
                        </div>
                    </MaxWidth>
                </section>
                <section className="section__six">
                    <Image src="/static/covid-19-bg-img.png" defer={true}>
                        <div className="section__six--inner">
                            <div className="section__six--copy">
                                <h2>
                                    Overall patient and staff safety is our top priority
                                </h2>
                                <p className="text-sm">
                                    Amidst the global health crisis, American Addiction
                                    Centers is strictly following the Centers for Disease
                                    Control and Prevention and World Health Organization’s
                                    recommendations and taking as many proactive actions
                                    as possible to protect our current and future patients
                                    and staff.
                                </p>
                            </div>
                            <div className="section__six--covid-19">
                                <Image
                                    src="/static/icon-covid.svg"
                                    width="72px"
                                    height="72"
                                    defer={true}
                                />
                                <div className="section__six--covid-19-copy">
                                    <span className="text-md">COVID-19</span>
                                    <p className="text-xs">
                                        American Addiction Centers facilities remain open
                                        and committed to providing critical addiction
                                        treatment amidst Coronavirus.&nbsp;
                                        <Link
                                            href="/covid-19-coronavirus-information/"
                                            aria-label="Learn More"
                                            title="Learn More"
                                            style={{
                                                color: 'var(--primary)',
                                                textDecoration: 'none',
                                            }}>
                                            Learn More
                                        </Link>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </Image>
                </section>
            </Layout>
            <style jsx>{`
                p {
                    margin: unset;
                }
                .section__hero {
                    background: linear-gradient(
                        -180deg,
                        rgb(232, 241, 245) 0%,
                        rgb(255, 255, 255) 100%
                    );
                    padding: 40px 15px 0px 15px;
                }
                .section__hero--copy {
                    margin-bottom: 24px;
                }
                .section__hero--copy h1 {
                    margin-bottom: 40px;
                }
                .section__hero--button-container {
                    display: grid;
                    grid-template-columns: minmax(100%, auto);
                    justify-content: center;
                    grid-row-gap: 15px;
                }
                .help-line {
                    text-align: center;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section__hero {
                        padding: 80px;
                    }
                    .section__hero--inner {
                        max-width: 1170px;
                        margin: 0 auto;
                        display: grid;
                        grid-template-columns: 1fr 1fr;
                        align-items: center;
                    }
                    .section__hero--inner-left {
                        max-width: 476px;
                        margin-right: 40px;
                    }
                    .section__hero--button-container {
                        grid-template-columns: minmax(230px, auto);
                        justify-content: start;
                    }
                }
                .section__two {
                    margin: 40px 0;
                    padding: 0 15px;
                }
                .section__two--card {
                    height: 200px;
                    border-left: 7px solid var(--primary);
                    border-radius: 8px;
                    padding: 55px 15px;
                    text-align: end;
                    margin-bottom: 40px;
                    color: #fff;
                    font-weight: bold;
                }
                .section__two--card .text-md {
                    font-size: 24px;
                    font-weight: bold;
                    height: 32px;
                    letter-spacing: -0.48px;
                    line-height: 32px;
                }
                .section__two--card > :global(a) {
                    font-size: 16px;
                    text-decoration: none;
                    display: flex;
                    font-size: 16px;
                    font-weight: normal;
                    height: 24px;
                    letter-spacing: -0.16px;
                    line-height: 24px;
                    justify-content: flex-end;
                    align-items: center;
                }
                .section__two--card > :global(a:hover) {
                    color: #fff;
                    text-decoration: underline;
                }
                .section__two--card > :global(a > span) {
                    margin-right: 13px;
                    color: #fff;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section__two {
                        display: grid;
                        grid-template-columns: 1fr 1fr 1fr;
                        grid-column-gap: 40px;
                        margin: 0 0 80px 0;
                    }
                    .section__two--card {
                        margin-bottom: 0;
                    }
                }
                .section__three {
                    margin-bottom: 40px;
                    padding: 0 15px;
                }
                .section__three--insurance-logos {
                    display: grid;
                    grid-template-columns: 1fr 1fr;
                    justify-items: center;
                    grid-row-gap: 20px;
                    margin: 35px auto;
                }
                .section__three--insurance-buttons {
                    display: grid;
                    grid-template-columns: minmax(100%, auto);
                    justify-content: center;
                    grid-row-gap: 16px;
                    text-align: center;
                }

                @media screen and (min-width: ${BREAKPOINT}) {
                    .section__three--copy {
                        text-align: center;
                    }
                    .section__three--insurance-logos {
                        grid-template-columns: repeat(6, 1fr);
                    }
                    .section__three--insurance-buttons {
                        grid-template-columns: repeat(2, minmax(230px, auto));
                        grid-column-gap: 20px;
                    }
                }

                .section__four--inner {
                    margin: auto;
                    padding: 40px 15px;
                    display: grid;
                    grid-template-columns: 1fr;
                    grid-template-areas:
                        'logo'
                        'icons'
                        'copy'
                        'button';
                    justify-items: center;
                    grid-row-gap: 30px;
                }

                .section__four--aac-logo {
                    grid-area: logo;
                }
                .section__four--treatment-icons {
                    display: grid;
                    grid-template-columns: repeat(4, 1fr);
                    grid-column-gap: 8px;
                    grid-area: icons;
                    color: #fff;
                }
                .section__four--treatment-icon {
                    max-width: 80px;
                    text-align: center;
                    font-weight: bold;
                    line-height: 16px;
                }
                .section__four--treatment-icon > .text-xs {
                    font-size: 12px;
                    font-weight: bold;
                    max-height: 32px;
                    letter-spacing: -0.16px;
                    line-height: 16px;
                    text-align: center;
                }
                .section__four--copy {
                    grid-area: copy;
                    color: #fff;
                }
                .section__four--button {
                    grid-area: button;
                    width: 100%;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section__four--inner {
                        max-width: 1170px;
                        padding: 80px;
                        grid-template-columns: repeat(2, 470px);
                        grid-template-areas:
                            'copy logo'
                            'button icons';
                        grid-row-gap: 0;
                    }
                    .section__four--button {
                        justify-self: left;
                        align-self: center;
                        width: initial;
                    }
                }
                .section__five {
                    background-color: #e8f1f5;
                    padding: 0 15px;
                }
                .section__five--inner {
                    padding: 40px 0;
                    display: grid;
                    justify-items: center;
                    grid-row-gap: 40px;
                }
                .section__five--copy > p {
                    margin-bottom: 24px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section__five--inner {
                        grid-template-columns: 30% 70%;
                        padding: 80px;
                    }
                }
                .section__six--inner {
                    padding: 40px 15px;
                }
                .section__six--copy {
                    color: #fff;
                    margin: 0 auto 40px;
                }
                .section__six--covid-19 {
                    background-color: #e8f1f5;
                    border-radius: 5px;
                    padding: 32px 15px;
                    display: grid;
                    justify-items: center;
                    grid-row-gap: 25px;
                }
                .section__six--covid-19-copy {
                    text-align: center;
                }
                .section__six--covid-19-copy > span {
                    display: block;
                    font-weight: bold;
                    margin-bottom: 16px;
                }
                @media screen and (min-width: ${BREAKPOINT}) {
                    .section__six--inner {
                        padding: 80px 0;
                        max-width: 770px;
                        margin: auto;
                    }
                    .section__six--copy {
                        text-align: center;
                    }
                    .section__six--covid-19 {
                        grid-template-columns: 15% 85%;
                        grid-column-gap: 24px;
                        padding: 32px 24px;

                        border-radius: 10px;
                        border: 1px solid #055276;
                        box-shadow: var(--box-shadow);
                        background: #e8f1f5;
                    }
                    .section__six--covid-19-copy {
                        text-align: left;
                    }
                    .section__six--covid-19-copy .text-xs {
                        font-size: 12px;
                        font-weight: normal;
                        letter-spacing: -0.16px;
                        line-height: 16px;
                    }
                }
            `}</style>
        </>
    );
};

export default Home;
